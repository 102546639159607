import React, { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { EditAnimatedMetaInput } from "../../Dashboards/Panel/util";
import { hasSpecialCharacters, isNumericType } from "../../util";
import TypeSelection from "./TypeSelection";
import UnitSelection from "./UnitSelection";
import { beamtoast } from "../../../common/CustomToast";

type AddOrEditColumnProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: (isOpen: boolean) => void;
  readonly trigger: React.ReactNode;
  readonly type: "add" | "edit";
  readonly tableName: string;
  readonly onSubmit: (
    tableName: string,
    columnName: string,
    columnType: string,
    columnUnit: string | null
  ) => void;
  readonly column: { name: string; type: string; unit: string | null };
  readonly columnNameSet: Set<string>;
};

function AddOrEditColumn(props: AddOrEditColumnProps) {
  const {
    type,
    isOpen,
    setIsOpen,
    trigger,
    tableName,
    onSubmit,
    column,
    columnNameSet,
  } = props;

  const [columnName, setColumnName] = useState<string>("");
  const [columnType, setColumnType] = useState<string>("");
  const [columnUnit, setColumnUnit] = useState<string | null>(null);

  const isValid = columnType !== "" && columnName !== "";

  const handleModalClose = () => {
    setColumnName("");
    setColumnType("");
    setColumnUnit(null);

    setIsOpen(false);
  };

  const handleSubmit = () => {
    if (type === "edit") {
      onSubmit(tableName, columnName, columnType, columnUnit);
      handleModalClose();
    } else {
      if (columnNameSet?.has(columnName)) {
        beamtoast.error(`${columnName} is already present`);
      } else if (hasSpecialCharacters(columnName)) {
        beamtoast.error(
          "Field Name cannot contain special characters except underscore"
        );
      } else {
        onSubmit(tableName, columnName, columnType, columnUnit);
        handleModalClose();
      }
    }
  };

  useEffect(() => {
    if (!isNumericType(columnType)) {
      setColumnUnit(null);
    }
  }, [columnType]);

  useEffect(() => {
    const { name, type, unit } = column;
    if (isOpen) {
      setColumnName(name);
      setColumnType(type);
      setColumnUnit(unit);
    }
  }, [isOpen, column]);

  return (
    <Modal
      closeIcon
      size="tiny"
      className="dark"
      closeOnDimmerClick={false}
      onClose={() => handleModalClose()}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      trigger={trigger}
    >
      <Modal.Header>{tableName}: Add New Column</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <EditAnimatedMetaInput
              disabled={type === "edit"}
              elementid="field-name"
              placeholder="Field Name"
              label={"Field Name"}
              value={columnName}
              onChangeEvent={(e) => {
                const value = e.target.value?.replace(/\s+/g, "_")?.trim();
                setColumnName(value);
              }}
              style={{ width: "100%" }}
            />
            <TypeSelection
              value={columnType}
              onChange={(_e, d) => setColumnType(d.value)}
              disabled={false}
              id={"add_column-type"}
              editMode={type === "edit"}
            />
            <UnitSelection
              id={"add_column-unit"}
              value={columnUnit ?? ""}
              onChange={(_e, d) => setColumnUnit(d.value as string)}
              columnTypeValue={columnType}
              style={{
                marginTop: "10px",
              }}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button secondary onClick={() => setIsOpen(false)}>
          Discard
        </Button>
        <Button primary onClick={() => handleSubmit()} disabled={!isValid}>
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AddOrEditColumn;
