import React, { useState, useEffect, useMemo } from "react";
import { Button, Icon, Popup, Table } from "semantic-ui-react";
import { EditRoleModal, EditRoleModalProps } from "./CreateOrEditRoleModal";
import {
  Role,
  IRoleResult,
  IUserResult,
  ApiKey,
  ActionType,
} from "../../../../util";
import PermissionView from "./PermissionView";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import Conditional from "../../common/Conditional";
import Layout from "../../common/Layout";
import * as uuid from "uuid";
import { ButtonIcon, DisplayIf } from "../../util";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  deleteRole,
  fetchAllRoles,
  createRole,
  editRole,
  fetchAllUsers,
  fetchAllApiKeys,
  fetchAllActionTypes,
} from "../../../../BytebeamClient";
import { Mixpanel } from "../../common/MixPanel";
import LoadingAnimation from "../../../common/Loader";
import { beamtoast } from "../../../common/CustomToast";
import { CardContainer } from "../../../common/ActionsUtils";

interface PermissionEditorProps {
  readonly role: Role;
  readonly users: IUserResult;
  readonly apiKeys: ApiKey[];
  readonly onClickEdit: () => void;
  readonly onClickClone: () => void;
  readonly onUpdate: () => void;
}

const isRoleInUserRoles = (
  userResult: IUserResult,
  role: Role,
  apiKeys: ApiKey[]
) => {
  return Object.values(userResult.result).some(
    (userRole) =>
      userRole?.roles.includes(role.id) ||
      Object.values(apiKeys).some((apiKey) => apiKey?.role.includes(role.name))
  );
};

function PermissionEditor({
  role,
  onClickEdit,
  onClickClone,
  onUpdate,
  users,
  apiKeys,
}: PermissionEditorProps) {
  if (role.name === "admin") {
    return <></>;
  }

  return (
    <div style={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
      <ButtonIcon name="edit" link onClick={onClickEdit} />
      <ButtonIcon
        link
        onClick={onClickClone}
        title="Clone This Role"
        name="clone"
      />
      {isRoleInUserRoles(users, role, apiKeys) ? (
        <Popup
          trigger={
            <ButtonIcon
              link
              name="trash"
              disabled={isRoleInUserRoles(users, role, apiKeys)}
            />
          }
          content={"Role is assigned to some users"}
          inverted
          position="top center"
        />
      ) : (
        <ConfirmationModal
          prefixContent="Delete role"
          expectedText={role.name}
          onConfirm={async () => {
            try {
              await deleteRole(role.id);
              beamtoast.success(`Deleted role '${role.name}' successfully`);
              onUpdate();
            } catch (e) {
              beamtoast.error(`Failed to delete role '${role.name}'`);
              console.log(e);
            }
          }}
          trigger={
            <ButtonIcon
              link
              name="trash"
              disabled={isRoleInUserRoles(users, role, apiKeys)}
            />
          }
          message={
            <ConfirmationModalMessage
              name={role.name}
              type={"Role"}
              specialMessage="This action only works if no user is assigned to this role."
            />
          }
        />
      )}
    </div>
  );
}

export default function Roles({ user }) {
  const [roles, setRoles] = useState<IRoleResult>({ result: [] });
  const [users, setUsers] = useState<IUserResult>({ result: {} });
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [actionTypes, setActionTypes] = useState<ActionType[]>([]);
  const [roleModalProps, setRoleModalProps] = useState<EditRoleModalProps>({
    key: uuid.v4(),
    isOpen: false,
    user: user,
    title: "",
    onSubmit: (role) => {},
    onCancel: () => {},
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  const permissions = user.role.permissions;
  const currentUserRole = user.role.name;

  const closeRoleModal = () => {
    setRoleModalProps({
      ...roleModalProps,
      isOpen: false,
    });
  };

  const initCreateRoleModal = () => {
    setRoleModalProps({
      key: uuid.v4(),
      isOpen: true,
      title: "Create Role",
      onSubmit: async (role) => {
        closeRoleModal();
        try {
          await createRole({ name: role.name, permissions: role.permissions });
          Mixpanel.track("Created Role", {
            roleName: role.name,
          });
          beamtoast.success(`Created role '${role.name}' successfully`);
          onUpdate();
        } catch (e) {
          Mixpanel.track("Failure", {
            type: "role creation",
            error: JSON.stringify(e),
          });
          beamtoast.error(`Failed to create role '${role.name}'`);
          console.log(e);
        }
      },
      onCancel: closeRoleModal,
      user,
    });
  };

  const initEditRoleModal = (role: Role) => {
    setRoleModalProps({
      key: uuid.v4(),
      isOpen: true,
      role: role,
      title: "Edit Role",
      onSubmit: async (role) => {
        closeRoleModal();
        try {
          await editRole(role.id, {
            name: role.name,
            permissions: role.permissions,
          });
          Mixpanel.track("Updated Role", {
            roleName: role.name,
          });
          beamtoast.success(`Role '${role.name}' is updated successfully`);
          onUpdate();
        } catch (e) {
          Mixpanel.track("Failure", {
            type: "role editing",
            error: JSON.stringify(e),
          });
          beamtoast.error(`Failed to edit role '${role.name}'`);
          console.log(e);
        }
      },
      onCancel: closeRoleModal,
      user,
    });
  };

  const initCloneRoleModal = (role: Role) => {
    let oldRoleName = role.name?.trim();
    role.name = `${role.name} (clone)`;
    setRoleModalProps({
      key: uuid.v4(),
      isOpen: true,
      role: {
        id: -1,
        name: role.name.trim(),
        permissions: role.permissions,
      },
      title: "Clone Role",
      onSubmit: async (role) => {
        closeRoleModal();
        try {
          await createRole({
            name: role.name.trim(),
            permissions: role.permissions,
          });
          Mixpanel.track("Cloned Role", {
            roleName: role.name,
          });
          beamtoast.success(`Role '${role.name}' is created successfully`);
          onUpdate();
        } catch (e) {
          Mixpanel.track("Failure", {
            type: "role cloning",
            error: JSON.stringify(e),
          });
          beamtoast.error(`Failed to clone role '${role.name}'`);
          console.log(e);
        }
      },
      onCancel: () => {
        role.name = oldRoleName;
        closeRoleModal();
      },
      user,
    });
  };

  const roleNameMap = useMemo(() => {
    const map = {};
    for (const role of roles.result) {
      map[role.id] = role.name;
    }
    return map;
  }, [roles]);

  const onUpdate = async () => {
    setLoading(true);
    try {
      const resUser = await fetchAllUsers();
      setUsers(resUser);
      const res = await fetchAllRoles();
      setRoles({ result: res });
      if (currentUserRole === "admin") {
        const apiKeys = await fetchAllApiKeys();
        const actionTypes = await fetchAllActionTypes();
        setActionTypes(actionTypes);
        setApiKeys(apiKeys);
      } else {
        setApiKeys([]);
        setActionTypes([]);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setErrorOccurred(true);
    }
  };

  useEffect(() => {
    document.title = "Roles | Bytebeam";
    onUpdate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading roles"
      />
    );
  }
  return (
    <CardContainer>
      <DisplayIf cond={permissions.editRoles}>
        <EditRoleModal {...roleModalProps} />
      </DisplayIf>
      <Layout
        buttons={
          <DisplayIf cond={permissions.editRoles}>
            <Button
              primary
              floated="right"
              icon
              labelPosition="left"
              onClick={() => initCreateRoleModal()}
            >
              <Icon name="plus" />
              Create Role
            </Button>
          </DisplayIf>
        }
      >
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>

              <Table.HeaderCell>Name</Table.HeaderCell>

              <Table.HeaderCell>Permissions</Table.HeaderCell>

              <DisplayIf cond={permissions.editRoles}>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </DisplayIf>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {roles.result.length !== 0 ? (
              roles.result.map((role) => (
                <Table.Row key={role.id}>
                  <Table.Cell>{role.id}</Table.Cell>
                  <Table.Cell>{role.name}</Table.Cell>
                  <Table.Cell>
                    <Conditional
                      condition={role.name === "admin"}
                      then={<div>Admin has all permissions</div>}
                      else={
                        <PermissionView
                          permission={role.permissions}
                          roleNameMap={roleNameMap}
                          actionTypes={actionTypes}
                        />
                      }
                    />
                  </Table.Cell>

                  <DisplayIf cond={permissions.editRoles}>
                    <Table.Cell>
                      <PermissionEditor
                        role={role}
                        onClickEdit={() => initEditRoleModal(role)}
                        onClickClone={() => initCloneRoleModal(role)}
                        onUpdate={onUpdate}
                        users={users}
                        apiKeys={apiKeys}
                      />
                    </Table.Cell>
                  </DisplayIf>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={`${permissions.editRoles ? "4" : "3"}`}>
                  <ErrorMessage marginTop="30px" message={"No Roles Found!"} />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Layout>
    </CardContainer>
  );
}
