import { AggregateValueData, AggregateValueMetaData } from "./PanelDef";
import React, { Component } from "react";
import { PanelViewComponent } from "../PanelDef";
import { Table, TableHeaderCell } from "semantic-ui-react";
import {
  StyledGrid,
  StyledGridRow,
  StyledTitle,
  StyledValue,
  TableType,
} from "../LastValue/ViewLastValue";
import { breakpoints } from "../../../../common/breakpoints";
import { OverflowDiv, TableContainer, formatValue } from "../util";
import { capitalizeFirstLetter } from "../../../util";
import { DashboardType } from "../../EditDashboardModal";

type AggregateTableProps = {
  data: any;
  panelMeta: AggregateValueMetaData;
  table: Array<TableType>;
};

class AggregateTable extends Component<AggregateTableProps> {
  render() {
    const aggregateTable = this.props.panelMeta?.table;
    const column = this.props.panelMeta.column;
    const aggregator = this.props.panelMeta.aggregator;
    const second_aggregator = this.props.panelMeta?.second_aggregator;
    const isSecondAggregatorGroupByEnabled =
      this.props.panelMeta?.aggregate_over_first_result ?? false;
    const enableUnits = this.props.panelMeta.enableUnits;
    const autoScaleUnits = this.props.panelMeta.autoScaleUnits;
    const enableRoundoff = this.props.panelMeta.enableRoundoff;
    const roundOffPrecision = this.props.panelMeta.roundOffPrecision ?? 3;
    const { data, table } = this.props;

    function getText(data): string {
      let columnUnit = table?.find(
        (column) => column?.name === data?.column
      )?.unit;
      const value = data?.value;
      let text = formatValue(
        value,
        columnUnit,
        false,
        enableRoundoff,
        roundOffPrecision,
        enableUnits,
        autoScaleUnits
      );
      if (
        data?.aggregator === "count" ||
        data?.aggregator === "count_distinct"
      ) {
        columnUnit = null;
      }

      return `${data?.prefix} ${text} ${data?.suffix}`;
    }

    const renderSecondAggregatorWithGroupByTableBody = () => {
      const aggregateName =
        typeof aggregator === "string" ? aggregator : aggregator[0];
      const prefix = this.props.panelMeta.prefix;
      const suffix = this.props.panelMeta.suffix;

      return (
        <>
          {data.map((deviceData) => {
            const value =
              deviceData[`${second_aggregator}.${aggregateName}.${column}`];
            let textValue = getText({
              column: column,
              prefix: prefix,
              suffix: suffix,
              aggregator: second_aggregator ?? aggregateName,
              value,
            });
            return (
              <Table.Row
                key={deviceData[`${aggregateTable}.${aggregateName}.${column}`]}
              >
                <Table.Cell>
                  {String(
                    deviceData?.[`${aggregateTable}.${aggregateName}.${column}`]
                  )}
                </Table.Cell>
                <Table.Cell>{textValue}</Table.Cell>
              </Table.Row>
            );
          })}
        </>
      );
    };

    return (
      <TableContainer>
        <div className="tableContentContainer">
          <Table compact selectable unstackable style={{ minWidth: "230px" }}>
            <Table.Header>
              {data?.id ? (
                <>
                  {data?.["-serial_metadata"] ? (
                    <Table.Row>
                      <TableHeaderCell>
                        {`#${capitalizeFirstLetter(Object?.keys(data?.["-serial_metadata"]).toString())}`}
                      </TableHeaderCell>
                      <TableHeaderCell>
                        {
                          data?.["-serial_metadata"][
                            Object.keys(data?.["-serial_metadata"])[0]
                          ]
                        }
                      </TableHeaderCell>
                    </Table.Row>
                  ) : (
                    <Table.Row>
                      <TableHeaderCell>Device</TableHeaderCell>
                      <TableHeaderCell>{data?.id}</TableHeaderCell>
                    </Table.Row>
                  )}
                </>
              ) : (
                <Table.Row>
                  <TableHeaderCell>
                    {isSecondAggregatorGroupByEnabled
                      ? `${aggregator} (Group By: ${column})`
                      : "Aggregator"}
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {second_aggregator ? `${second_aggregator}` : "Value"}
                  </TableHeaderCell>
                </Table.Row>
              )}
            </Table.Header>

            <Table.Body>
              {isSecondAggregatorGroupByEnabled ? (
                renderSecondAggregatorWithGroupByTableBody()
              ) : (
                <>
                  {typeof aggregator === "string"
                    ? (() => {
                        let value = data?.[aggregator];
                        if (second_aggregator) {
                          value =
                            data?.[
                              `${second_aggregator}.${aggregator}.${column}`
                            ];
                        }
                        let textValue = getText({
                          column: column,
                          prefix: this.props.panelMeta.prefix,
                          suffix: this.props.panelMeta.suffix,
                          aggregator: second_aggregator ?? aggregator,
                          value,
                        });

                        return (
                          <Table.Row>
                            <Table.Cell>
                              {second_aggregator
                                ? `${second_aggregator}(${aggregator})`
                                : aggregator}
                            </Table.Cell>
                            <Table.Cell>{textValue}</Table.Cell>
                          </Table.Row>
                        );
                      })()
                    : aggregator.map((aggregateName) => {
                        let value = data?.[aggregateName];
                        if (second_aggregator) {
                          value =
                            data?.[
                              `${second_aggregator}.${aggregateName}.${column}`
                            ];
                        }

                        let textValue = getText({
                          column: column,
                          prefix: this.props.panelMeta.prefix,
                          suffix: this.props.panelMeta.suffix,
                          aggregator: second_aggregator ?? aggregateName,
                          value,
                        });

                        return (
                          <Table.Row key={aggregateName}>
                            <Table.Cell>{aggregateName}</Table.Cell>
                            <Table.Cell>{textValue}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                </>
              )}
            </Table.Body>
          </Table>
        </div>
      </TableContainer>
    );
  }
}

class FleetListView extends Component<AggregateTableProps> {
  render() {
    const aggregator = this.props.panelMeta.aggregator;
    const second_aggregator = this.props.panelMeta?.second_aggregator;
    const enableUnits = this.props.panelMeta.enableUnits;
    const autoScaleUnits = this.props.panelMeta.autoScaleUnits;
    const enableRoundoff = this.props.panelMeta.enableRoundoff;
    const roundOffPrecision = this.props.panelMeta.roundOffPrecision ?? 3;
    const { data, table } = this.props;

    function getText(data): string {
      let columnUnit = table?.find(
        (column) => column?.name === data?.column
      )?.unit;
      const value = data?.value;
      let text = formatValue(
        value,
        columnUnit,
        false,
        enableRoundoff,
        roundOffPrecision,
        enableUnits,
        autoScaleUnits
      );

      if (
        data?.aggregator === "count" ||
        data?.aggregator === "count_distinct"
      ) {
        columnUnit = null;
      }
      return `${data?.prefix} ${text} ${data?.suffix}`;
    }

    return (
      <Table compact selectable unstackable style={{ minWidth: "230px" }}>
        <Table.Header>
          <Table.Row>
            {data[0]?.["-serial_metadata"] ? (
              <TableHeaderCell key="serial">
                {`#${capitalizeFirstLetter(Object?.keys(data[0]?.["-serial_metadata"]).toString())}`}
              </TableHeaderCell>
            ) : (
              <TableHeaderCell key="id">Device ID</TableHeaderCell>
            )}
            {aggregator.map((agg, index) => (
              <TableHeaderCell key={index}>{agg}</TableHeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data?.map((deviceData, index) => {
            return (
              <Table.Row key={index}>
                {/* If "-serial_metadata" is present, then show the first key as the device name else show the device id */}
                {deviceData?.["-serial_metadata"] ? (
                  <Table.Cell>
                    {
                      deviceData?.["-serial_metadata"][
                        Object.keys(deviceData?.["-serial_metadata"])[0]
                      ]
                    }
                  </Table.Cell>
                ) : (
                  <Table.Cell>{deviceData.id}</Table.Cell>
                )}
                {aggregator.map((agg, aggIndex) => {
                  let value = deviceData[agg];
                  let textValue = getText({
                    column: this.props.panelMeta.column,
                    prefix: this.props.panelMeta.prefix,
                    suffix: this.props.panelMeta.suffix,
                    aggregator: second_aggregator ?? agg,
                    value,
                  });

                  return <Table.Cell key={aggIndex}>{textValue}</Table.Cell>;
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

export class ViewAggregateValue extends PanelViewComponent<
  AggregateValueMetaData,
  AggregateValueData
> {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  ref = React.createRef<HTMLDivElement>();
  state = {
    fontSize: 10,
    fontSizeFactor: 469,
    screenWidth: window.innerWidth,
  };

  canvas = document.createElement("canvas");

  getTextWidth(text, font) {
    let context = this.canvas.getContext("2d");
    if (context) {
      context.font = font;

      let metrics = context.measureText(text);
      return metrics.width;
    }

    return 0;
  }

  getText(data) {
    const table = this.props.tables[
      this.props.panelMeta.table
    ] as Array<TableType>;

    const enableUnits = this.props.panelMeta.enableUnits;
    const autoScaleUnits = this.props.panelMeta.autoScaleUnits;
    const enableRoundoff = this.props.panelMeta.enableRoundoff;
    const roundOffPrecision = this.props.panelMeta.roundOffPrecision ?? 3;
    let columnUnit = table?.find(
      (column) => column?.name === data?.column
    )?.unit;
    if (data?.aggregator === "count" || data?.aggregator === "count_distinct") {
      columnUnit = null;
    }
    const value = data?.value;
    let text = formatValue(
      value,
      columnUnit,
      false,
      enableRoundoff,
      roundOffPrecision,
      enableUnits,
      autoScaleUnits
    );

    return `${data?.prefix} ${text} ${data?.suffix}`;
  }

  calculateFontSize(fontFamily, maxWidth, maxHeight) {
    const text = this.getText({
      column: this.props.panelMeta.column,
      prefix: this.props.panelMeta.prefix,
      suffix: this.props.panelMeta.suffix,
      aggregator:
        this.props.panelMeta.second_aggregator ??
        this.props.panelMeta.aggregator[0],
      value: this.props.data?.data?.value,
    });
    let numIterations = 0;
    let start = 10;
    let end = Math.max(10, Math.min(200, maxHeight));

    while (true) {
      const fontSize = (start + end) / 2;
      const font = `${fontSize}px ${fontFamily}`;

      numIterations += 1;

      if (numIterations > 20) {
        return { fontSize, characters: text.length };
      }

      const textWidth = this.getTextWidth(text, font);
      if (Math.abs(textWidth - maxWidth) < 2 || Math.abs(end - start) < 2) {
        return { fontSize, characters: text.length };
      }

      if (textWidth < maxWidth) {
        start = fontSize;
      } else {
        end = fontSize;
      }
    }
  }

  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  componentDidMount() {
    if (this.ref.current) {
      const rect = this.ref.current.getBoundingClientRect();
      const fontFamily = window.getComputedStyle(this.ref.current).fontFamily;

      const { fontSize, characters } = this.calculateFontSize(
        fontFamily,
        rect.width * 0.9,
        rect.height * 0.5
      );
      let sizeFactor = fontSize * characters;
      this.setState({
        fontSize: 0.8 * fontSize,
        fontSizeFactor: sizeFactor,
      });
    }

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const dashboardType = this.props.dashboardMeta.type;
    const panelMeta = this.props.panelMeta;
    const table = this.props.tables[panelMeta.table] as Array<TableType>;
    const dashboardTitle = panelMeta?.title;
    const data: any = this.props?.data?.data;
    let allData = this.props.data?.["allData"];

    if (
      allData?.[0]?.id &&
      allData?.length > 0 &&
      dashboardType === DashboardType.FleetDashboard
    ) {
      allData = allData.sort((a, b) => {
        return parseInt(a.id) - parseInt(b.id);
      });

      return (
        <TableContainer>
          <div className="tableContentContainer">
            {!panelMeta.fleetTableView ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  overflow: "auto",
                }}
              >
                {allData.map((data) => {
                  return (
                    <AggregateTable
                      key={data?.id}
                      data={data}
                      panelMeta={panelMeta}
                      table={table}
                    />
                  );
                })}
              </div>
            ) : (
              <FleetListView
                data={allData}
                panelMeta={panelMeta}
                table={table}
              />
            )}
          </div>
        </TableContainer>
      );
    }

    if (typeof panelMeta.aggregator === "string") {
      // For older Aggregator version, when Aggregate value was string
      let textValue = this.getText({
        column: panelMeta.column,
        prefix: panelMeta.prefix,
        suffix: panelMeta.suffix,
        aggregator: panelMeta.second_aggregator ?? panelMeta.aggregator,
        value: data?.value,
      });
      let estimatedFontSize = this.state.fontSizeFactor / textValue.length;

      if (this.state.screenWidth > breakpoints.sm) {
        return (
          <div className="big-number-root" ref={this.ref}>
            <div className="big-number-value">
              <span
                style={{
                  fontSize: `${estimatedFontSize ?? 20}px`,
                }}
              >
                {textValue}
              </span>
            </div>
          </div>
        );
      } else {
        return (
          <StyledGrid>
            <StyledGridRow>
              <StyledTitle width={8}>
                {dashboardTitle || "Untitled"}
              </StyledTitle>
              <StyledValue width={8}>{textValue}</StyledValue>
            </StyledGridRow>
          </StyledGrid>
        );
      }
    }

    if (
      (panelMeta.aggregator.length === 1 &&
        dashboardType === DashboardType.DeviceDashboard) ||
      (dashboardType === DashboardType.FleetDashboard &&
        panelMeta?.second_aggregator &&
        Object.keys(data)?.length === 1 &&
        (panelMeta?.aggregate_over_first_result === undefined ||
          panelMeta?.aggregate_over_first_result === false))
    ) {
      // For latest version which allows multiple Aggregator
      // But with single aggregator
      const aggregator = panelMeta?.aggregator?.[0];
      const second_aggregator = panelMeta?.second_aggregator;

      const autoTextSize = panelMeta?.autoTextSize;
      const customTextSize = panelMeta?.textSize;

      let value = data?.[aggregator];
      if (second_aggregator) {
        value =
          data?.[`${second_aggregator}.${aggregator}.${panelMeta?.column}`];
      }

      let textValue = this.getText({
        column: panelMeta?.column,
        prefix: panelMeta?.prefix,
        suffix: panelMeta?.suffix,
        aggregator: second_aggregator ?? aggregator,
        value,
      });
      let estimatedFontSize = this.state.fontSizeFactor / textValue.length;

      if (this.state.screenWidth > breakpoints.sm) {
        return (
          <OverflowDiv className="big-number-root" ref={this.ref}>
            <div className="big-number-value">
              <span
                style={{
                  fontSize: autoTextSize
                    ? `${estimatedFontSize ?? 20}px`
                    : `${customTextSize}px`,
                  lineHeight: "normal",
                }}
              >
                {textValue}
              </span>
            </div>
          </OverflowDiv>
        );
      } else {
        return (
          <StyledGrid>
            <StyledGridRow>
              <StyledTitle width={8}>{dashboardTitle}</StyledTitle>
              <StyledValue width={8}>{textValue}</StyledValue>
            </StyledGridRow>
          </StyledGrid>
        );
      }
    }

    return <AggregateTable data={data} panelMeta={panelMeta} table={table} />;
  }
}
