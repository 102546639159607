import React from "react";
import { Input, InputProps } from "semantic-ui-react";
import styled from "styled-components";

export const StyledInput = styled(Input)<{ background?: string }>`
  margin-bottom: 0 !important;
  color: ${({ theme }) => theme.colors["foreground-color"]} !important;
  background: ${({ theme, background }) =>
    background ?? theme.colors["search_input-background"]} !important;
  border-radius: 6px !important;
  border: ${({ theme }) => theme.colors["search_input-border"]} !important;
  box-shadow: ${({ theme }) =>
    theme.colors["search_input-box_shadow"]} !important;
  input {
    color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    background: ${({ theme, background }) =>
      background ?? theme.colors["search_input-background"]} !important;
    border-radius: 6px !important;
  }

  input::placeholder {
    color: ${({ theme }) =>
      theme.colors["search_input-placeholder-color"]} !important;
  }

  input:focus::placeholder {
    color: ${({ theme }) =>
      theme.colors["search_input-placeholder-color-focus"]} !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .dropdown {
    color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    border-left: none !important;
    .divider.text {
      color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    }
    &::focus {
      background: ${({ theme }) =>
        theme.colors["search_input-background"]} !important;
      outline: none !important;
    }
  }
`;

// Use React.forwardRef to forward the ref to the StyledInput
const DevicesFilterSearchInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => <StyledInput {...props} ref={ref} />
);

export default DevicesFilterSearchInput;
