import React, { useState } from "react";
import styled from "styled-components";
import { useStatusBarContext } from "../../context/StatusBar.context";
import moment from "moment";
import { Icon } from "semantic-ui-react";

const StatusBarContainer = styled.div<{ showWarning: boolean }>`
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme, showWarning }) =>
    theme.colors[
      showWarning
        ? "status_bar-background-color-warning"
        : "status_bar-background-color"
    ]};
  color: ${({ theme, showWarning }) =>
    theme.colors[
      showWarning ? "status_bar-text-color-warning" : "status_bar-text-color"
    ]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 48px;
  height: 36px;
  font-size: 13px;
  z-index: 1000;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.15);
  transition:
    background-color 0.3s,
    color 0.3s;
`;

const StatusBarSections = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StatusBarTabs = styled.div<{ showWarning: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 100%;
  width: fit-content;
  &:hover {
    background: ${({ theme, showWarning }) =>
      theme.colors[
        showWarning
          ? "status_bar-background-color-warning-hover"
          : "status_bar-background-color-hover"
      ]};
    cursor: pointer;

    .notification-indicator {
      border: 2px solid
        ${({ theme, showWarning }) =>
          theme.colors[
            showWarning
              ? "status_bar-background-color-warning-hover"
              : "status_bar-background-color-hover"
          ]};
    }
  }
`;

const WarningText = styled.strong`
  color: #fff;
`;

const PopupContainer = styled.div`
  position: absolute;
  bottom: 42px;
  right: 12px;
  background-color: ${({ theme }) =>
    theme.colors["status_bar-background-color"]};
  color: ${({ theme }) => theme.colors["status_bar-text-color"]};
  border: 1px solid #555;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 420px;
  z-index: 1100;
`;

const CenterAlignedIcon = styled(Icon)<{ showWarning: boolean }>`
  && {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: ${({ theme, showWarning }) =>
      theme.colors[
        showWarning ? "status_bar-text-color-warning" : "filter-btn_text"
      ]};
    margin: 0px;
    padding: 0px;
  }
`;

const BellContainer = styled.div`
  position: relative;
  font-size: 15px;
`;

const Indicator = styled.div<{ showWarning: boolean }>`
  position: absolute;
  top: -2px;
  right: -1px;
  width: 10px;
  height: 10px;
  background-color: ${({ theme, showWarning }) =>
    theme.colors[
      showWarning ? "status_bar-text-color-warning" : "filter-btn_text"
    ]};
  border-radius: 50%;
  border: 2px solid
    ${({ theme, showWarning }) =>
      theme.colors[
        showWarning
          ? "status_bar-background-color-warning"
          : "status_bar-background-color"
      ]};
`;

export const StatusBar = ({ planExpiryDate }) => {
  const { autoRefresh } = useStatusBarContext();
  const [isPopupVisible, setPopupVisible] = useState(false);

  const formattedDate = new Date(planExpiryDate).toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const showPlanExpiryWarning = Boolean(planExpiryDate);
  const showWarning = showPlanExpiryWarning || autoRefresh.isRefreshPaused;

  const togglePopup = () => {
    setPopupVisible((prev) => !prev);
  };

  return (
    <>
      <StatusBarContainer showWarning={showPlanExpiryWarning}>
        <StatusBarSections>
          {showPlanExpiryWarning && (
            <WarningText>
              Your current plan will expire on {formattedDate}. Please ensure
              all dues are cleared to avoid service interruption.
            </WarningText>
          )}
        </StatusBarSections>
        <StatusBarSections>
          {autoRefresh.lastRefreshedTime && (
            <StatusBarTabs showWarning={showPlanExpiryWarning}>
              <CenterAlignedIcon
                name="refresh"
                style={{ margin: "3px 6px 0px 0px" }}
                showWarning={showPlanExpiryWarning}
              />
              <span>Last refreshed:</span>{" "}
              <span>
                {moment(autoRefresh.lastRefreshedTime).format("HH:mm:ss")}
              </span>
            </StatusBarTabs>
          )}
          <StatusBarTabs
            showWarning={showPlanExpiryWarning}
            onClick={togglePopup}
          >
            <BellContainer>
              <CenterAlignedIcon name="bell" showWarning={showWarning} />
              {showWarning && (
                <Indicator
                  className="notification-indicator"
                  showWarning={showWarning}
                />
              )}
            </BellContainer>
          </StatusBarTabs>
        </StatusBarSections>
      </StatusBarContainer>
      {isPopupVisible && (
        <PopupContainer>
          {showWarning ? (
            <div>
              <div
                style={{
                  paddingBottom: "8px",
                  marginBottom: "8px",
                  width: "100%",
                  borderBottom: "1px solid #555",
                }}
              >
                <strong>Warning</strong>
              </div>
              {showPlanExpiryWarning && (
                <p>Your current plan will expire on {formattedDate}.</p>
              )}
              {autoRefresh.isRefreshPaused &&
                autoRefresh.refreshPage === "device-management" && (
                  <p>Auto-refresh paused due to active device shadow filters</p>
                )}
            </div>
          ) : (
            <div>
              <p>NO NEW NOTIFICATIONS</p>
            </div>
          )}
        </PopupContainer>
      )}
    </>
  );
};
