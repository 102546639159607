import React, { useEffect, useState } from "react";
import { AlertRule } from "../../../../util";
import { DashboardsInfo } from "../../Dashboards/ViewDashboard";
import { fetchPanelData } from "../../../../BytebeamClient";
import { DashboardType } from "../../Dashboards/EditDashboardModal";
import uuid from "uuid";
import { FetchParams } from "../../Dashboards/Panel/util";
import { AlertsData } from "../../Dashboards/Panel/Alerts/PanelDef";
import {
  RelativeTimeRange,
  TimeRange,
} from "../../Dashboards/Datetime/TimeRange";
import LoadingAnimation from "../../../common/Loader";
import { CardContainer } from "../../../common/ActionsUtils";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { DateTimeDropdown } from "../../Dashboards/Datetime/DateTimeDropdown";
import { DateTimeDropdownWrapper } from "../../Actions/Logs/LogsManagement";
import styled from "styled-components";
import AlertsComponent from "./AlertsComponent";
import Toggle from "../../../common/Toggle";
import { PaginationProps } from "semantic-ui-react";

type AlertsDashboardProps = {
  readonly alertRules: AlertRule[];
  readonly alertGroups: string[];
  readonly dashboards: DashboardsInfo[];
};

const AlertsDashboardHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  align-items: center;
`;

function AlertsDashboard(props: AlertsDashboardProps) {
  const { alertRules, alertGroups, dashboards } = props;

  const [panelData, setPanelData] = useState<AlertsData | null>(null);
  const [timeRange, setTimeRange] = useState<TimeRange>(
    new RelativeTimeRange(5, "minutes")
  );
  const [filterBys, setFilterBys] = useState({}); // eslint-disable-line @typescript-eslint/no-unused-vars

  const [activeTab, setActiveTab] = useState<string>("All Alerts");
  const [groupByAlertGroups, setGroupByAlertGroups] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [alertsPerPage, setAlertsPerPage] = useState<number>(10); // eslint-disable-line @typescript-eslint/no-unused-vars

  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  const onTimeRangeChange = (timeRange: TimeRange) => {
    setTimeRange(timeRange);
  };

  const refreshData = async (page: number = activePage) => {
    setLoading(true);

    try {
      const abortController = new AbortController();
      let alertArray = groupByAlertGroups
        ? alertGroups
        : alertRules.map((rule) => rule.name);
      alertArray = activeTab === "All Alerts" ? alertArray : [activeTab];

      const panelMeta = {
        type: "alerts",
        id: uuid.v4(),
        title: "Alerts Dashboard Panel",
        description: "Alerts Dashboard in alerts Tab",
        aggregates: [],
        tabbedView: true,
        tabByAlertGroups: groupByAlertGroups,
        tabList: activeTab === "All Alerts" ? "all" : activeTab,
        page: page,
        limit: alertsPerPage,
        groupBys: ["id"],
        ...(groupByAlertGroups
          ? { alert_groups: alertArray }
          : { alert_names: alertArray }),
      };
      const fetchParams: FetchParams = {
        groupBys: [],
        fetchAll: false,
        timeRange: timeRange,
        filterBys: filterBys,
      };

      const panelsData = await fetchPanelData(
        [panelMeta],
        fetchParams,
        abortController.signal,
        DashboardType.FleetDashboard
      );

      setPanelData(panelsData[0]?.data ?? null);
      setErrorOccurred(false);
    } catch (error) {
      console.error(error);
      setErrorOccurred(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) => {
    if (data.activePage) {
      setActivePage(data.activePage as number);
      await refreshData(data.activePage as number);
    }
  };

  useEffect(() => {
    refreshData();
  }, [filterBys, timeRange, groupByAlertGroups, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderContent = () => {
    if (loading) {
      return (
        <LoadingAnimation
          fontSize="16px"
          loaderContainerHeight="60vh"
          loaderSize="42px"
          loadingText={`Loading Alerts...`}
        />
      );
    }

    if (errorOccurred) {
      return (
        <ErrorMessage
          containerHeight={"59vh"}
          iconSize={"large"}
          marginTop={"0px"}
          message={"Failed to fetch Alerts data"}
        />
      );
    }

    if (!panelData) {
      return <ErrorMessage message="No alerts data available" />;
    }

    return (
      <AlertsComponent
        data={panelData}
        alertGroups={alertGroups}
        aggregates={[]}
        loading={loading}
        totalPages={Math.ceil(panelData.totalCount / alertsPerPage)}
        dashboards={dashboards}
        handlePageChange={handlePageChange}
        activePage={activePage}
        setActivePage={setActivePage}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        groupByAlertGroups={groupByAlertGroups}
      />
    );
  };

  return (
    <CardContainer>
      <AlertsDashboardHeader>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <div>Group by Alert Groups</div>
          <div>
            <Toggle
              id="group-by-alert-groups"
              size="medium"
              checked={groupByAlertGroups}
              onChange={() => {
                setGroupByAlertGroups(
                  (groupByAlertGroups) => !groupByAlertGroups
                );
              }}
              style={{
                top: "4px",
              }}
            />
          </div>
        </div>

        <DateTimeDropdownWrapper>
          <DateTimeDropdown
            showControls={true}
            timeRange={timeRange}
            onTimeRangeChange={onTimeRangeChange}
            disabled={loading}
          />
        </DateTimeDropdownWrapper>
      </AlertsDashboardHeader>

      {renderContent()}
    </CardContainer>
  );
}

export default AlertsDashboard;
