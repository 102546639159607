import React, { createContext, useContext, useState } from "react";

type AutoRefreshPages = "device-management" | "";
export type AutoRefreshDetailsType = {
  lastRefreshedTime: Date | null;
  isRefreshPaused: boolean;
  refreshPage: AutoRefreshPages;
};

type StatusBarContextType = {
  autoRefresh: AutoRefreshDetailsType;
  setAutoRefresh: (autoRefresh: AutoRefreshDetailsType) => void;
};

const StatusBarContext = createContext<StatusBarContextType | undefined>(
  undefined
);

export const StatusBarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [autoRefresh, setAutoRefresh] = useState<AutoRefreshDetailsType>({
    lastRefreshedTime: null,
    isRefreshPaused: false,
    refreshPage: "",
  });

  return (
    <StatusBarContext.Provider
      value={{
        autoRefresh,
        setAutoRefresh,
      }}
    >
      {children}
    </StatusBarContext.Provider>
  );
};

export const useStatusBarContext = () => {
  const context = useContext(StatusBarContext);
  if (!context) {
    throw new Error(
      "useStatusBarContext must be used within a StatusBarProvider"
    );
  }
  return context;
};
